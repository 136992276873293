import React, {useState, useEffect, useContext} from 'react';
import { Helmet } from "react-helmet";
import { WebappContext, AccountContext, WhiteLabelContext, GeolocationContext, LanguageContext } from "ToolboxUtils/web/context/context";
import MajorsView from 'ToolboxComponents/webapp/pages/majors-view/majors-view';
import Loader from 'ToolboxComponents/commons/loader/loader';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';

import { PAGE_LOAD_WAITING } from 'Params/globals';

const Results = props => {
  const [signIn, setSignIn] = useContext(WebappContext).usePath('modals.signIn');
  const [account, setAccount] = useContext(AccountContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [coordinates] = useContext(GeolocationContext).usePath('coordinates');

  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [isLoaderCountDownSet, setLoaderCountDown] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);

  if (!isLoaderCountDownSet) {
    setLoaderCountDown(true);
    setTimeout(function(){
      if (!products.isComplete) {
        showLoader(true);
      }
    }, PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
  }

  useEffect(() => {
    return () => {
      setLoaderCountDown(false);
      setPageInitTime(null);
    }
  }, []);

  const products = useApiChuchoteurs.get('/products', {
    params: {
      filter: 'majors',
      language,
      whiteLabelId: whiteLabel.id,
      isInZone: whiteLabel.isInZone,
      longitude: coordinates.longitude,
      latitude: coordinates.latitude
    },
    noTrack: ['time']
  });

  const updateFav = id => {
    if (account.favorites.includes(id)) {
      const favorites = account.favorites.filter(favorite => favorite !== id);
      setAccount('favorites', favorites);
    } else {
      const favorites = account.favorites.concat([id]);
      setAccount('favorites', favorites);
    }
  }

  const openSignInModal = status => {
    setSignIn({opened: true, from: 'fav'});
  }

  useIsContentLoaded(products.isComplete);

  return (
    <>
      {products.isComplete ?
        <MajorsView
          products={products.data}
          addFavorite={async (id) => {
            if (account && account.id) {
              try {
                const favorite = await apiChuchoteurs.post(`/accounts/${account.id}/products/${id}/favorites`, {
                  token: localStorage.token
                })
                updateFav(id);
              } catch(err) {
                apiChuchoteurs.post(`/logs`, {
                  body: {error: {...err, type: 'front'}}
                })
              }
            } else {
              openSignInModal(true);
            }
          }}
          deleteFavorite={async (id) => {
            if (account && account.id) {
              try {
                const favorite = await apiChuchoteurs.delete(`/accounts/${account.id}/products/${id}/favorites`, {
                  token: localStorage.token
                })
                updateFav(id);
              } catch(err) {
                apiChuchoteurs.post(`/logs`, {
                  body: {error: {...err, type: 'front'}}
                })
              }
            } else {
              openSignInModal(true);
            }
          }}
        />
        : isLoaderDisplayed
          ? <Loader />
          : <></>
      }
    </>
  );
}

export default Results;
